<template>
  <div>
    <Header back="返回" title="众创服务" index="首页" titleOne="官网管理" titleTwo="众创服务" beforeTitle="新增/编辑" />
    <div class="content">
      <h1>基本信息</h1>
      <span style="margin-bottom: 15px;display: block;">封面</span>
      <Give ref="givecover" />
      <el-form
        :rules="formRules"
        label-position="top"
        ref="form"
        :model="giveParams"
        label-width="80px"
      >
        <el-form-item label="企业名称" prop="company_name">
          <el-input v-model="giveParams.company_name"></el-input>
        </el-form-item>
        <el-form-item label="服务年数">
          <el-input v-model="giveParams.service_year"></el-input>
        </el-form-item>
        <el-form-item label="是否认证">
          <el-select v-model="giveParams.is_auth" placeholder="请选择是否认证" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="giveParams.tel"></el-input>
        </el-form-item>
        <el-form-item label="办公地址" style="width: 49.5%;">
          <el-input v-model="giveParams.address"></el-input>
        </el-form-item>
        <el-form-item label="官网">
          <el-input v-model="giveParams.official_website"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="giveParams.email"></el-input>
        </el-form-item>
        <el-form-item label="简介" style="width: 100%;">
          <el-input type="textarea" v-model="giveParams.introduction" rows="5" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%;">
          <h1>工商信息</h1>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="giveParams.code"></el-input>
        </el-form-item>
        <el-form-item label="企业名称">
          <el-input v-model="giveParams.company_name"></el-input>
        </el-form-item>
        <el-form-item label="法定代表人">
          <el-input v-model="giveParams.legal_person"></el-input>
        </el-form-item>
        <el-form-item label="登记状态">
          <el-select v-model="giveParams.status" placeholder="请选择登记状态" style="width: 100%;">
            <el-option label="在业" :value="1"></el-option>
            <el-option label="存续" :value="2"></el-option>
            <el-option label="正常" :value="3"></el-option>
            <el-option label="注销" :value="4"></el-option>
            <el-option label="筹建" :value="5"></el-option>
            <el-option label="清算" :value="6"></el-option>
            <el-option label="迁入" :value="7"></el-option>
            <el-option label="迁出" :value="8"></el-option>
            <el-option label="停业" :value="9"></el-option>
            <el-option label="撤销" :value="10"></el-option>
            <el-option label="吊销" :value="11"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成立日期">
          <el-date-picker
            style="width: 100%;"
            v-model="giveParams.register_time"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="注册资本（万元）">
          <el-input v-model="giveParams.register_capital"></el-input>
        </el-form-item>
        <el-form-item label="组织机构代码">
          <el-input v-model="giveParams.org_code"></el-input>
        </el-form-item>
        <el-form-item label="实缴资本（万元）">
          <el-input v-model="giveParams.payment"></el-input>
        </el-form-item>
        <el-form-item label="工商注册号">
          <el-input v-model="giveParams.register_number"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号">
          <el-input v-model="giveParams.taxpayer_number"></el-input>
        </el-form-item>
        <el-form-item label="企业类型">
          <el-select v-model="giveParams.region" placeholder="请选择企业类型" style="width: 100%;">
            <el-option label="其他责任" :value="10"></el-option>
            <el-option label="实业单位" :value="11"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="纳税人资质">
          <el-input v-model="giveParams.taxpayer_qualification"></el-input>
        </el-form-item>
        <el-form-item label="营业期限">
          <el-date-picker
            style="width: 100%;"
            v-model="giveParams.business_term_start"
            type="date"
            placeholder="选择开始日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="-">
          <el-date-picker
            style="width: 100%;"
            v-model="giveParams.business_term_end"
            type="date"
            placeholder="选择结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属行业">
          <el-select v-model="giveParams.industry_one" placeholder="请选择行业一" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="-">
          <el-select v-model="giveParams.industry_two" placeholder="请选择行业二" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属地区（省）">
          <el-select v-model="giveParams.province_id" placeholder="请选择省" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市">
          <el-select v-model="giveParams.city_id" placeholder="请选择市" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区">
          <el-select v-model="giveParams.town_id" placeholder="请选择区" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登记机关">
          <el-input v-model="giveParams.register_office"></el-input>
        </el-form-item>
        <el-form-item label="人员规模">
          <el-select v-model="giveParams.personal_size" placeholder="请选择" style="width: 100%;">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参保人数">
          <el-input v-model="giveParams.insured_amount"></el-input>
        </el-form-item>
        <el-form-item label="校准日期">
          <el-date-picker
            style="width: 100%;"
            v-model="giveParams.approval_date"
            type="date"
            placeholder="选择开始日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="国际行业">
          <el-select
            v-model="giveParams.international_industry"
            placeholder="请选择"
            style="width: 100%;"
          >
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;width: 49.5%;justify-content: space-between;flex-wrap: wrap;">
          <el-form-item label="英文名" style="width: 49%;min-width: 240px;">
            <el-input v-model="giveParams.company_name_en"></el-input>
          </el-form-item>
          <el-form-item label="进出口企业代码" style="width: 49%;min-width: 240px;">
            <el-input v-model="giveParams.trade_code"></el-input>
          </el-form-item>
        </div>
        <div style="width: 100%;display: flex;justify-content: space-between;flex-wrap: wrap;">
          <el-form-item label="注册地址" style="width: 49.5%;min-width: 480px;">
            <el-input v-model="giveParams.register_address"></el-input>
          </el-form-item>
          <el-form-item label="最新年报地址" style="width: 49.5%;min-width: 480px;">
            <el-input v-model="giveParams.last_report"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="经营范围" style="width: 100%;">
          <el-input type="textarea" v-model="giveParams.business_scope" rows="5" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div style="width: 100%;text-align: center;">
        <el-button type="primary" class="sure" :loading="btnLoading" @click="sure()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Gqburl from '../../components/global.vue'
import Give from '../../components/upload/uploadCover.vue'
export default {
  components: {
    Give,
  },
  data () {
    return {
      btnLoading: false,//提交按钮动画
      gqbpath: Gqburl.userSite,
      giveParams: {},//参数 / 字段
      formRules: {
        company_name: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ]
      },//表单规则
      infoCover: '',//回显封面
    }
  },
  created () {
    if (this.$route.query.id) {
      this.giveView()
    }
  },
  methods: {
    giveView () {
      this.axios.get('/api/website/view_service', {
        params: {
          id: this.$route.query.id
        }
      }).then((res) => {
        this.giveParams = res.data
        this.infoCover = res.data.cover_url
      })
    },
    sure () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            this.giveParams.cover_url = this.infoCover
          } else {
            this.giveParams.cover_url = this.$refs.givecover.fileCover.url
          }
          this.btnLoading = true
          // console.log(this.giveParams, 5555)
          this.axios.post('/api/website/store_service', { ...this.giveParams }).then((res) => {
            this.$message.success(res.message)
            this.btnLoading = false
            this.$router.go(-1)
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          this.$message.warging('请完善信息')
        }
      })
    },
  }

}
</script>

<style scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
div/deep/.el-form-item {
  width: 24%;
  min-width: 240px;
}
</style>